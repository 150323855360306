<template>
    <div class="home">
    <div class="form">
      <el-form ref="form" label-width="180px">
<el-button type="primary"  @click="getimage2(1)">比例1:1</el-button>
<el-button type="primary"  @click="getimage2(2)">比例3:4</el-button>
<el-button type="primary"  @click="getimage2(3)">比例4:3</el-button>
<el-button type="primary"  @click="down()">保存</el-button>
      <div class="pt20"></div>
      剪切后的图片质量（0.1-1） <el-slider v-model="option.outputSize" :max='1' :min='0.1' :step='0.01' ></el-slider>
        <div class="cropper">
          <vueCropper
          style="width:100%;height:400px;"
          ref="cropper"
                :img="option.img"
                :outputSize="option.size"
                :outputType="option.outputType"
                :info="true"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :fixedBox="option.fixedBox"
                :fixedNumber="option.fixedNumber"
                :fixed="option.fixed"
          ></vueCropper>
        </div>
        <div class="pt20"></div>
          <div style="display:flex">
            
                     <draggable
                     style="display:flex"
                      class="list-group"
                      tag="ul"
                      v-model="list"
                      v-bind="dragOptions"
                      @start="drag = true"
                      @end="drag = false"	
                    >
            <div class="block" @click="getimage(img)" v-for="(img,index) in list" :key="img.id">
               <i @click.stop="deleimage(index)" class="el-icon-circle-close imgii"></i>
               <div class="fengmian" v-if="index == 0">封面</div>
                    <el-image
                    style="width: 150px; height: 150px"
                    :src="img.src"
                    fit="fill">
                    </el-image>
            </div>
            <input ref="filElem" type="file" name='file[]' hidden class="upload-file" @change="getFile" multiple>
            <div @click="choiceImg" class="el-upload el-upload--picture-card">
                <i v-if="list.length<6" style="margin-top: 55px;margin-left: 10px;" class="el-icon-plus"></i>
            </div>
            
                    </draggable>
          </div>

            <el-form-item style="margin-top:20px;margin-left: 125px;">
                <el-button type="primary"  @click="onSubmit">完成</el-button>
            </el-form-item>
        </el-form>
  </div>
      <div class="pt20"></div>
    </div>
</template>
<script>

import axios from 'axios'
import config from '../../lib/config'
import draggable from 'vuedraggable'

export default {
  name: 'Home',
  components: {
    draggable
  },
  data(){
    return {
        list:[],
        option: {
          id:'',
          img: '',
          outputSize:1, //剪切后的图片质量（0.1-1）
          full: true,//输出原图比例截图 props名full
          outputType: 'png',
          canMove: true, 
          original: false, 
          canMoveBox: true, 
          autoCrop: true, 
          autoCropWidth: 300, 
          autoCropHeight: 300, 
          fixedBox: false ,
          fixedNumber:[1,1],
          fixed:true,//是否开启截图框宽高固定比例
        }, 
    }
  },
  props: {
    fileList: Array
  },
  methods: {
    onSubmit() {
      this.$emit("getimgflie", this.list);
    },
    down() {
      let _this = this;
      console.log(_this.list)
            this.$refs.cropper.getCropBlob((data) => {
            let file = new File([data], 'name'+new Date().getTime()+'m.png', {type: data.type, lastModified: Date.now()});
             for(let i in _this.list){
               if(_this.list[i].id == _this.option.id){
                 console.log(_this.list[i]);
                 _this.list[i].file = file;
               }
             }

            })
           //base64
            this.$refs.cropper.getCropData((data) => {
             let src = data;
             for(let i in _this.list){
               if(_this.list[i].id == _this.option.id){
                 _this.list[i].src = src;
               }
             }
            })
            
        },
    deleimage(index){
      if(this.option.id == this.list[index].id){
        this.option.img = '';
        this.option.id = '';
      }
      this.list.splice(index, 1);
    },
    getimage(img){
        this.option.img = img.src;
        this.option.id = img.id;
    },
    getimage2(int){
      if(int === 1){
        this.option.fixedNumber = [1,1];
      }else if(int === 2){
        this.option.fixedNumber = [3,4];
      }else{
        this.option.fixedNumber = [4,3];
      }
        
    },
    deleteData(index) {// 删除
        this.list.splice(index, 1);
    },
    choiceImg(){
        if(this.list.length>5){
            return;
        }
         this.$refs.filElem.dispatchEvent(new MouseEvent('click')) 
    },
    getFile(){
            var that = this;
            let files = this.$refs.filElem.files;
            //console.log(files.length)
            for(let i in files){
              if(i == 'length'){
                return;
              }else{
                if(this.list.length+i*1+1> 6){
                  return
                }
              }
              let inputFile = files[i];
           // console.log(inputFile)
          //  const inputFile = this.$refs.filElem.files[0];
            if(inputFile){
                if(inputFile.type !== 'image/jpeg' && inputFile.type !== 'image/png' && inputFile.type !== 'image/gif'){
                  that.$message.error('上传文件中有格式不对');
                    return;
                }
                if(inputFile.size > 10000000){//大于10mb不行
                  that.$message.error('单张图片大小不得超过10MB');
                  return;
                }
                
                this.imgInfo = Object.assign({}, this.imgInfo, {
                    name: inputFile.name,
                    size: inputFile.size,
                    lastModifiedDate: inputFile.lastModifiedDate.toLocaleString()
                })
                const reader = new FileReader();
                reader.readAsDataURL(inputFile);
                reader.onload = function () {
                    let id = new Date().getTime();
                    that.imgSrc = this.result;
                    that.option.img = that.imgSrc;
                    that.option.id = id;
                //  that.setImageUpload(inputFile);
                    that.list.push({
                        id:id,
                        src:that.imgSrc,
                        file:inputFile,
                    });
                }
            } else {
                return;
            }
            }
        },
        setImageUpload:function(file){
          let _this = this;
       let formdata = new FormData();
     
        formdata.append("file[]", file);
      
           axios.post(config.articleImageUpload,formdata,{headers:{'Content-Type':'multipart/form-data'}})
        .then(function (response) {
            if(response.data.code == 200){
              
                    let id = new Date().getTime();
                    _this.option.img = response.data.data;
                    _this.option.id = id;
                   // let src =  /^data:image/.test(response.data.data) ? response.data.data : response.data.data + '?' + new Date().getTime();
                    _this.list.push({
                        id:id,
                        src:response.data.data,
                    });
                    
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },

    
  },
  created(){
     this.list = this.fileList;
     this.option.img = '';
     this.option.id = '';
  },
  computed:{
    dragOptions() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        };
      }
  }
}
</script>
<style scoped >
.imgii{
  position: absolute;
  right:-10px;
  z-index: 999;
  font-size: 24px;
  top: -10px;
}
.fengmian{
  position: absolute;
  z-index: 998;
}
.block{
  position: relative;margin-right: 15px;
}
</style>